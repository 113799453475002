import React from 'react'

import cssServicios from '../styles/servicioespecial.module.scss'
import bombillo from '../assets/BOMBILLO DE INNOVA.jpg'
import terra from '../assets/TERRA.jpg'
import ioisystems from '../assets/IOISYSTEMS.jpg'

import Layout from "../components/layout"

const ServiciosEsp = () => {
  return (
    <Layout>
      <div className={cssServicios.raiz}>
        <div className={cssServicios.banner}>
          <h1 className={cssServicios.tituloprincipal}>Sirviendo a la Industria Mexicana del Petróleo y Gas</h1>
        </div>
        {/*<div className={cssServicios.modelo}>
          <img src={modelo} className={cssServicios.foto}/>
        </div>*/}

        <section className={cssServicios.seccionuno}>
          <h2 className={cssServicios.titulo}>Servicios Especiales</h2>
          <p className={cssServicios.parrafo}>Adquisición Satelital de información de subsuelo, para la determinación de presencia de hidrocarburos (TECNOLOGÍA  STeP-Terra Insight).</p>
          <p className={cssServicios.parrafo}>Control absoluto del comportamiento del pozo  bajo el concepto de la Gerencia de la Energía de Yacimiento, (TECNOLOGÍA AWPA).</p>
          <p className={cssServicios.parrafo}>Limpieza de Pozos mediante reacción exotérmica. (TECNOLOGÍA AMB EXOTERM).</p>
          <p className={cssServicios.parrafo}>Monitoreo en Tiempo Real.</p>
          <p className={cssServicios.parrafo}>Equipo Multidisciplinario de PREDRILL y Seguimiento.</p>
          <p className={cssServicios.parrafo}>Servicio de Outsourcing especializado.</p>
        </section>

        <aside className={cssServicios.imagenes}>
          <img className={cssServicios.bombillo} src={bombillo} alt={""}/>
          <img className={cssServicios.terra} src={terra} alt={""}/>
          <img className={cssServicios.ioisystems} src={ioisystems} alt={""}/>
        </aside>

        {/*<div className={cssServicios.yacimiento}>
          <img src={pozo} className={cssServicios.foto}/>
        </div>*/}

      </div>
    </Layout>
  )
}

export default ServiciosEsp;